import { userAxios } from '../index'

const officeVisitList = async (perPage) => {
    try {
        return await userAxios.get(`officeVisits/all/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const officeVisitListPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`officeVisits/all/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const verifyCupon = async (data) => {
    try {
        return await userAxios.post(`cupons/verify`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const verifyMembership = async (id) => {
    try {
        return await userAxios.get(`memberships/active/patient/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const officeVisitFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`officeVisits/search/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const officeVisitFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`officeVisits/search/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const officeVisitDetailt = async (id) => {
    try {
        return await userAxios.get(`officeVisits/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const roomsAvailable = async () => {
    try {
        return await userAxios.get(`getAvailableRooms`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const roomsCheckAvailable = async (id, idRoom) => {
    try {
        return await userAxios.get(`officeVisits/assignRoom/${id}/${idRoom}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureNurse = async (id, data) => {
    try {
        return await userAxios.post(`officeVisits/nurse/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureOma = async (id, data) => {
    try {
        return await userAxios.post(`officeVisits/oma/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
const assignToOma = async (id) => {
    try {
        return await userAxios.post(`officeVisits/takeVisit`, id).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureCheck = async(id, data) => {
    try {
        return await userAxios.put(`officeVisits/allComplete/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedurePay = async(id, data) => {
    try {
        return await userAxios.post(`officeVisits/payment/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedurePayPartial = async(id, data) => {
    try {
        return await userAxios.post(`appointment/pending-pay/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const updateReasonVisit = async(id, data) => {
    try {
        return await userAxios.post(`officeVisits/update/reason_visit/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const dataForNurse = async(id) => {
    try {
        return await userAxios.get(`officeVisits/getDataNurse/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const dataForOMA = async(id) => {
    try {
        return await userAxios.get(`officeVisits/getDataOma/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    officeVisitList,
    officeVisitListPagination,
    officeVisitFilter,
    officeVisitFilterPagination,
    officeVisitDetailt,
    roomsAvailable,
    roomsCheckAvailable,
    procedureNurse,
    procedureOma,
    assignToOma,
    procedureCheck,
    procedurePay,
    procedurePayPartial,
    dataForNurse,
    dataForOMA,
    updateReasonVisit,
    verifyCupon, 
    verifyMembership
}
